import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';
import ItemLogo from '@components/molecules/items/ItemLogo/index';
import logo from '@assets/images/projects/fwt/website/FWT-Siteweb_logo.svg';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#F0140A';
const footerRelatedLinks = [
	{
		title: 'ECA Vaud',
		url: '/fr/projets/eca-vaud/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectFwtPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/fwt/website/FWT-Siteweb_header.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/fwt/website/FWT-Siteweb_header-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 768, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_001.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_002.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_003.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_004.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_005.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_006.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_007.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/fwt/website/fwt_siteweb_008.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/fwt-freeride-world-tour/',
					lang: 'en',
				},
			]}
			title="Freeride World Tour- Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="FWT - Freeride World Tour"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Retranscrire l’émotion unique du championnat du
							monde de Freeride dans un multisite.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Conception UX',
								'Design UI',
								'Développement Web Headless',
								'Interconnexion API',
								'Rebranding',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							Le Freeride World Tour est le championnat du monde
							de freeride gérant les compétitions
							professionnelles, de qualification, challenger et
							junior dans le monde. L’organisation a fait appel à
							superhuit pour refondre son site internet afin de
							mieux distinguer les différentes compétitions et
							leurs données, tout en générant de la transversalité
							pour augmenter la notoriété des compétitions
							challenger et junior. Ainsi que de proposer un site
							pouvant gérer les pics de trafic et les enjeux d’une
							audience mondiale.
						</p>
						<p>
							Technologiquement l’organisation change aussi son
							système de gestion de compétition, nécessitant le
							développement d’une synchronisation avec l’API pour
							le traitement des données.
						</p>

						<h3>Notre résponse</h3>
						<p>
							Nous avons accompagné l’organisation dans une
							clarification de son architecture en segmentant les
							compétitions via quatre sous-sites distincts.
							L’identité visuelle a été revisitée par la création
							de nouveaux codes graphiques pour le digital et le
							print, dans l’objectif de simplifier le rapport à la
							marque FWT.
						</p>
						<p>
							Technologiquement, l’ensemble du site a été
							développé en semi-statique avec React/NextJS
							permettant de réduire la charge du serveur, appuyé
							par un service de CDN pour optimiser le chargement
							au niveau mondial.
						</p>

						<hr />
						<h3>Pour approfondir</h3>
						<ButtonSecondary
							href="/fr/projets/fwt-freeride-world-tour-branding/"
							text="Découvrir le projet de branding FWT"
							data-target
							className="-red"
						/>
					</div>
				</div>

				<Video
					src="84a160a2afed4f4544c00ac5f7a93c2c"
					alt="Freeride World Tour - Site internet"
					className="col-full"
				/>

				<div className="box text-content">
					<div className="list-two-columns">
						<div className="text-content">
							<h3>
								Évolution de la marque et refonte de l’identité
							</h3>
							<p>
								<b>Une évolution de l’identité visuelle</b>
								<br />
								La première étape du nouveau site du Freeride
								World Tour correspond à la refonte de l’identité
								de marque de la compétition.
							</p>
							<p>
								Refonte des codes graphiques, comprenant le
								logo, les éléments graphiques et les choix
								typographiques.
							</p>
							<hr className="element-spacing" />
							<h4
								style={{
									color: accent_color,
									marginBottom: '20px',
								}}
							>
								Découvrir le projet de refonte de la marque
								Freeride World Tour
							</h4>
							<ButtonSecondary
								href="/en/projetcs/fwt-freeride-world-tour-branding/"
								text="Découvrir"
								data-target
								className="no-spacing"
							/>
						</div>
						<ItemLogo
							image={{
								src: logo,
								alt: 'Logo FWT',
							}}
							imgStyle={{
								width: '512px',
								maxWidth: '100%',
								maxHeight: '100%',
								margin: 'auto',
							}}
						/>
					</div>
				</div>

				<Image
					{...data.image_1.childImageSharp}
					alt="Evènement FWT"
					className="col-full"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_2.childImageSharp} alt="Ski" />
					<Image
						{...data.image_3.childImageSharp}
						alt="Site internet FWT sur mobile"
					/>
				</Gallery>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>
							Amélioration des rankings et du suivi des résultats
						</h3>
						<p>
							Le suivi des résultats est primordial sur un site de
							compétition. Étant donné que le FWT possède un
							fonctionnement singulier et qui diffère entre les
							compétitions du tour, nous avons travaillé avec le
							FWT à rendre la compréhension aussi simple que
							possible.
						</p>
						<p>
							<b>
								Plus de transparence et possibilité
								d’approfondir
							</b>
							<br />
							Le système de calcul des points est plus transparent
							dans sa compréhension, permettant aussi
							d’approfondir un résultat en le mettant en relation
							avec son événement ou en découvrant une vidéo du run
							du rider.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_4.childImageSharp}
							alt="Page de classement"
						/>
						<Video
							src="010b9e19e7876574b8bec5227c304323"
							alt="Page de classement féminin"
							className="grid-spacing box box--no-padding"
						/>
					</div>
				</Column>

				<div className="box text-content">
					<h3>
						Des pages qui enrichissent les histoires entre FWT et
						son audience
					</h3>
					<div className="list-two-columns grid-spacing">
						<div className="text-content">
							<p>
								Les pages du site sont conçues pour enrichir
								l’expérience permettant à l’audience de
								renforcer sa relation avec l’univers du freeride
								et des riders.
							</p>
							<p>
								Les pages dédiées aux riders en sont une
								parfaite illustration ; elles offrent des
								contenus exclusifs mettant en avant les vidéos
								les plus marquantes, des articles pertinents et
								des images, sans oublier, bien sûr, les
								résultats d’un rider dans les différentes
								compétitions.
							</p>
						</div>
						<div className="text-content">
							<p>
								<b>Un maillage interne renforcé</b>
								<br />
								L’ensemble des données sont interconnectées
								entre elles, permettant un maillage interne de
								qualité. Ainsi il est possible de mettre en
								relation la densité de contenu que comporte le
								FWT au fil des saisons et des riders selon des
								années, des thématiques, des résultats...
							</p>
						</div>
					</div>
				</div>

				<Image
					{...data.image_5.childImageSharp}
					alt="Page rider"
					className="col-full"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<div className="box box--no-padding col-start-1 col-end-1">
						<Video
							src="05417b5af6156ec5e6fb203711e7df8c"
							alt="FWT site web"
							className="video__middle"
						/>
					</div>
					<div className="box box--no-padding col-start-2 col-end-2 col-mobile">
						<Video
							src="57df78ea2715482d7efa05246687b987"
							alt="News FWT"
							className="video__middle"
						/>
					</div>
				</Gallery>

				<Blockquote
					modifiers="big"
					uptitle="Témoignage client"
					quote="Une collaboration fluide et réactive tout au long du projet brand et web. Superhuit a su prendre en considération nos enjeux stratégiques et nos contraintes business pour y apporter des solutions fortes."
					more={
						<>
							<span className="text-content">
								Superhuit a été un véritable partenaire
								stratégique tout au long du projet, en partant
								du branding pour arriver à l’expérience web.
								Leurs réflexions et opinions sont restées
								objectives et centrées sur les objectifs que
								nous avions établis, avec un souci
								d’amélioration constant.
							</span>
							<span className="text-content">
								Que ce soit par des meetings, des workshops ou
								d’innombrables échanges d’e-mails et de calls,
								nous avons pu établir une vraie relation de
								confiance avec l’ensemble de l’équipe !
							</span>
						</>
					}
					cite="Gabriel Wicki – Head of communication"
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Faire vivre et revivre la saison du freeride</h3>
						<p>
							Une saison est non seulement riche en émotions, mais
							aussi en production de contenu permettant de les
							faire vivre et revivre. L’objectif de la section
							média est de valoriser l’ensemble des vidéos,
							articles et images des compétitions.
						</p>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_6.childImageSharp}
							alt="News et vidéos FWT"
						/>
						<Image
							{...data.image_7.childImageSharp}
							alt="Articles FWT"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Évolution de la marque Freeride World Tour</h3>
						<p>
							Découvrez le travail d’évolution de la marque
							Freeride World Tour et de son univers graphique.
						</p>
						<ButtonSecondary
							href="/fr/projets/fwt-freeride-world-tour-branding/"
							text="Découvrir le projet de branding FWT"
							data-target
							className="-red small-spacing"
						/>
					</div>
					<div className="column__media text-content">
						<Image
							{...data.image_8.childImageSharp}
							alt="Branding FWT"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectFwtPage;
